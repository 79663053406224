*, *:after, *:before {
	-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;
	-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased;
}

html {
	font-size: 62.5%;
	
	&.locked { overflow: hidden; }
	&.waiting * { cursor: wait !important; }
}

body { margin: 0; }