.grid {
	@include flex; @include flex-wrap; width: auto; margin: 0; justify-content: flex-start;
	
	> div { position: relative; padding: 0; }
	
	.col-1 { width: 8.3333%; }
	.col-2 { width: 16.6667%; }
	.col-3 { width: 25%; }
	.col-4 { width: 33.3333%; }
	.col-5 { width: 41.6667%; }
	.col-6 { width: 50%; }
	.col-7 { width: 58.3333%; }
	.col-8 { width: 66.6667%; }
	.col-9 { width: 75%; }
	.col-10 { width: 83.3333%; }
	.col-11 { width: 91.6667%; }
	.col-12 { width: 100%; }
	
	&.gap-0-5 {
		margin: -0.5em;
		
		> div { padding: 0.5em; }
	}
}