.form {
	width: 100%; max-width: 640px; position: relative;
	
	.grid {
		margin: -0.75em -0.75em;
		
		> div { width: 100%; padding: 0.75em 0.75em; }
	}
	
	.fields {
		.item {
			position: relative;
			
			.input {
				input, textarea { width: 100%; padding: 0.6em 0.8em; font-size: 1em; background: none; border: solid 1px $color; @include placeholder($color); }
			}
		}
	}
	
	.agreements {
		margin: 1.5em 0 0;
		
		.item {
			position: relative; @include no-select;
			
			.input {
				font-size: 0.85em; line-height: 1.3;
				
				input[type="checkbox"] {
					display: none;
					
					+ .checkbox { width: 1em; height: 1em; float: left; margin: 0.2em 0.5em 0.1em 0; border: solid 1px $color; }
					
					&:checked {
						+ .checkbox { background: $color; }
					}
				}
				
				> div {
					font-size: 1em;
					
					a { text-decoration: underline; }
				}
			}
		}
	}
	
	.form-error {
		padding: 1px 0; font-size: 0.8em; color: $themeColorRed;
		
		a { color: $themeColorRed; text-decoration: underline; }
	}
	
	.submit {
		margin: 1.75em 0 0; text-align: center;
		
		.button {
			padding: 0.75em 1.5em; font-weight: 700; font-size: 1.5em; color: #fff; background: $themeColorRed;
			
			&:hover { background: #000; }
		}
	}
}