section.systems-list {
	@include flex; @include flex-wrap; margin: -1em -1em; padding-bottom: 3em; justify-content: center;
	
	.col {
		padding: 1em 1em;
		
		&.col-4 { width: 33.3333%; }
		
		button {
			display: block; width: 100%; position: relative; text-align: center;
			
			.image {
				width: 100%; height: 0; padding-top: 56.25%; position: relative; background-position: center; background-repeat: no-repeat; background-size: cover; border: solid 1px #f7f7f7; border-bottom: none;
				
				&:after { content: ''; width: auto; height: auto; position: absolute; top: 1em; left: 1em; right: 1em; bottom: 1em; border: solid 1px #fff; }
			}
			
			.label { padding: 0.5em; font-size: 1.3em; color: $themeColorRed; background: #f7f7f7; }
			
			&:hover {
				@include box-shadow(0, 0, 1em, rgba(0, 0, 0, 0.6));
			}
		}
	}
}