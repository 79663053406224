.popup-container {
	@include fill-absolute; background: rgba(255, 255, 255, 0.8); z-index: 1;
	
	.popup {
		width: 80%; max-width: 1280px; height: 80%; position: absolute; top: 50%; left: 50%; background: #fff; border: solid 1px #d0d0d0;
		@include transform(translate(-50%, -50%));
		
		.close { width: 2em; height: 2em; position: absolute; right: 0; bottom: 100%; margin-bottom: 1em; background: url('#{$img}icons/close.svg') center no-repeat; background-size: contain; }
		.toolbar { position: absolute; left: 0; bottom: 100%; }
		
		.popup-content {
			overflow-x: hidden; overflow-y: scroll; width: 100%; height: 100%;
			
			.content { position: relative; padding: 1.5em; text-align: center; }
		}
	}
}