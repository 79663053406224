.flex-list {
	@include flex; @include flex-wrap; margin: -0.25em -0.5em; padding: 0;
	
	li {
		margin: 0; padding: 0.25em 0.5em; font-size: 0.9em; list-style: none;
		
		> button { color: $colorLight; }
	}
	
	&-left { justify-content: flex-start; }
	&-center { justify-content: center; }
	&-right { justify-content: flex-end; }
	&-around { justify-content: space-around; }
	&-between { justify-content: space-between; }
	&-evenly { justify-content: space-evenly; }
	&-half li { width: 50%; }
}