.button-switch {
	overflow: hidden; width: 33px; position: relative; background: #fff; border: solid 2px #000; @include border-radius-rounded;
	
	span {
		display: block; width: 100%; height: 0; position: relative; padding-top: 100%; background-position: center; background-repeat: no-repeat; background-size: 65%;
		
		&.button-switch-handler { position: absolute; top: 0; left: 0; background: #d7d7d7; @include border-radius-rounded; @include transition(all, 0.5s, ease-out); }
		
		&.button-switch-type {
			&-day { background-image: url('#{$img}icons/environment/day.png'); }
			&-night { background-image: url('#{$img}icons/environment/night.png'); }
		}
	}
}