#application {
	.col-panel {
		width: 330px; z-index: 1;
		
		> section {
			@include flex; flex-direction: column; @include fill-absolute; background: #fff; box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
			
			.row-panel {
				flex: 1; overflow-x: visible; overflow-y: auto;
				
				.content {
					position: relative; padding: 0.6em; text-align: left;
					
					.title {
						position: relative;
						
						&:after { content: ''; display: block; overflow: hidden; width: 100%; height: 0; clear: both; }
						
						button { float: right; margin: 0 0 0 0.5em; }
					}
					
					.options-group { margin: 0 0 0.5em; }
					.option { position: relative; padding: 0.5em 0; }
					
					input[type="text"], input[type="number"], select, textarea { display: block; width: 100%; padding: 0.25em 0.5em; font-size: 0.8em; background: #fff; border: solid 1px $colorLight; }
					
					input[type="number"] {
						display: inline-block; width: 64px; padding-right: 2px;
						-moz-appearance: textfield;
						
						&::-webkit-outer-spin-button,
						&::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
					}
					
					select { margin-bottom: 4px; }
					
					.checkbox {
						@include flex; margin-bottom: 0.2em; align-items: center;
						
						> div { margin-left: 0.5em; font-size: 0.9em; line-height: 1; }
					}
				}
			}
			
			.row-switcher {
				button { width: 100%; padding: 0.75em; font-weight: 700; font-size: 1.5em; color: $themeColorRed; background: $themeColorGrey; }
			}
		}
	}
	
	.col-workspace {
		flex: 1; height: 100vh;
		
		> section {
			@include flex; flex-direction: column; @include fill-absolute;
			
			.row-workspace {
				flex: 1; overflow: hidden; position: relative;
				
				.canvas { overflow: hidden; @include fill-absolute; }
				.canvas-helper { overflow: hidden; @include fill-absolute; }
				
				.top-left, .top-right, .bottom-left, .bottom-right {
					@include flex; @include flex-wrap; position: absolute; align-items: flex-start; z-index: 1;
					
					> div { position: relative; }
					
					&.top-left {
						top: 0; left: 0;
						
						> div { margin-right: 1px; }
					}
					
					&.top-right {
						top: 0; right: 0;
						
						> div { margin-left: 1px; }
					}
					
					&.bottom-left {
						left: 0; bottom: 0;
						
						> div { margin-right: 1px; }
					}
					
					&.bottom-right {
						right: 0; bottom: 0;
						
						> div { margin-left: 1px; }
					}
				}
			}
		}
	}
	
	&.preview {
		.col-panel, .row-status-bar { display: none; }
	}
	
	
	/* --- WINDOW ---------------------------------- */
	
	&.window-none {
		.col-workspace {
			.row-workspace {
				.canvas-helper { display: none; }
			}
		}
	}
	
	&.window-vertical {
		.col-workspace {
			.row-workspace {
				.canvas { width: 50%; left: auto; }
				.canvas-helper { width: 50%; right: auto; border-right: solid 1px #000; }
			}
		}
	}
	
	&.window-horizontal {
		.col-workspace {
			.row-workspace {
				.canvas { height: 50%; top: auto; }
				.canvas-helper { height: 50%; bottom: auto; border-bottom: solid 1px #000; }
			}
		}
	}
	
	&.window-cascade-technical {
		.col-workspace {
			.row-workspace {
				.canvas-helper { width: auto; height: auto; top: 0; left: 0; right: 60%; bottom: 60%; margin: 1em; border: solid 1px #000; @include box-shadow(0, 0, 1em rgba(0, 0, 0, 0.5)); }
			}
		}
	}
	
	&.window-cascade-main {
		.col-workspace {
			.row-workspace {
				.canvas { width: auto; height: auto; top: 0; left: 0; right: 60%; bottom: 60%; margin: 1em; border: solid 1px #000; z-index: 1; @include box-shadow(0, 0, 1em rgba(0, 0, 0, 0.5)); }
			}
		}
	}
}