.communique {
	width: auto; max-width: 50%; position: absolute; top: -10em; left: 50%; z-index: 9999;
	@include transform(translate(-50%, 0%)); @include transition(all, 0.5s, ease-out);
	
	> div {
		padding: 0.5em 1em 0.6em; font-weight: 300; font-size: 1.2em; line-height: 1.2; text-align: center; background: rgba(255, 255, 255, 0.9);
		@include box-shadow(0, 0, 2em, rgba(0, 0, 0, 0.5));
	}
	
	&.active { top: 1em; }
}